.TechList {
    display: flex;
    flex-flow: row wrap;

    &-Tooltip {
        $animation-duration: .075s;
        $animation-fx: ease-in-out;

        pointer-events: none;
        white-space: nowrap;

        position: absolute;
        bottom: 100%;
        left: 50%;

        line-height: 1.6rem;
        padding: 0 8px;

        background: rgba(0, 0, 0, .8);
        border-radius: 4px;
        color: #ffffff;
        opacity: 0;

        transform: translate(-50%, 100%) scale(.2);
        transform-origin: center;
        transition:
                transform $animation-duration $animation-fx,
                opacity $animation-duration $animation-fx;
    }

    &-Item {
        position: relative;
        margin: 0 4px 4px 0;
        line-height: 0;
    }

    &-Item:hover &-Tooltip {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
    }

    &-Icon {
        &_small {
            transform: scale(.8);
        }
    }

    &_all {
        justify-content: center;
    }
}
