.ContactList {
    max-width: 800px;
    margin: 10px auto;
    padding: 0 20px;

    &-Items {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        padding: 5vh 2vw;

        background: #ffffff;
    }
}
