@use 'sass:math';
@import '../../../common.scss';

@mixin block-arrow($direction: right) {
    $path: ();
    $unshift: calc(100% - #{$arrow-shift});

    @if ($direction == right) {
        $path: (
            0 0,
            $unshift 0%,
            100% 50%,
            $unshift 100%,
            0% 100%
        );
    } @else {
        $path: (
            $arrow-shift 0%,
            100% 0%,
            100% 100%,
            $arrow-shift 100%,
            0% 50%
        );
    }
    -webkit-clip-path: polygon($path);
    clip-path: polygon($path);
}

$arrow-shift: 14px;

$width-date: 250px;
$height-date: 50px;

$line-margin: 20px;
$line-dot-left: #{$width-date + $line-margin};
$dot-size: 8px;

.Timeline-Entry {
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    padding-left: #{$dot-size * 4};

    @media screen and (min-width: $breakpoint-pad) {
        flex-direction: row;
        padding-left: unset;
    }

    /**
     * Левая часть
     */
    &Date {
        // flex-grow: 1;
        max-width: 90%;

        @media screen and (min-width: $breakpoint-pad) {
            width: $width-date;
        }

        &Time {
            display: inline-block;
            height: $height-date;

            padding: 0 #{$arrow-shift + 2px};

            background: #e86971;

            font-size: 1rem;
            font-weight: bold;
            line-height: $height-date;
            color: #ffffff;
            white-space: nowrap;

            @include block-arrow(left);

            @media screen and (min-width: $breakpoint-pad) {
                width: 100%;

                @include block-arrow(right);
            }
        }
    }

    &Content {
        position: relative;

        display: flex;
        flex-flow: column nowrap;
        flex: 1;

        margin: 12px $arrow-shift 2em;

        @media screen and (min-width: $breakpoint-pad) {
            margin-top: 0;
            margin-left: #{$arrow-shift * 2 + $dot-size + $line-margin};
            margin-right: #{$arrow-shift * 1.5};
        }

        &::after {
            content: "";
            display: table;
            clear: both;
        }

        h3 {
            display: flex;
            align-items: center;

            min-height: 50px;

            font-size: 2rem;
            line-height: 36px;
        }
    }

    &Body {
        margin-top: .5em;
        color: #222222;
    }

    &Image {
        max-height: 100px;
    }

    &Link {
        display: inline-block;
        line-height: 30px;
        padding: 0 16px;
        text-decoration: none;
        color: #222222;
        margin: 6px 0;
        transition: background .1s ease-in-out;

        &:hover {
            background: rgba($color: #000000, $alpha: .1);
        }
    }

    /**
     * Точка
     */
    &::after {
        @include size($dot-size);

        content: "";
        display: block;

        position: absolute;
        left: $line-dot-left;
        top: math.div($height-date, 2) - $dot-size;
        border: 4px solid;
        border-radius: 50%;

        background: white;
    }

    /**
     * Линия
     */
    &::before {
        content: "";
        display: block;

        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(#{$line-dot-left} + 6px);
        width: 4px;
        background: black;
    }

    /**
     * Линия у первого элемента
     */
    &:first-child::before {
        top: math.div($height-date, 2) - $dot-size;
    }

    /**
     * Линия у последнего элемента
     */
    &:last-child::before {
        bottom: calc(100% - math.div($height-date, 2) - $dot-size);
    }

    /**
     * Анимации
     */
    &Date,
    &Content {
        visibility: hidden;
    }

    &_visible &Date,
    &_visible &Content {
        visibility: visible;
        animation: timeline-show-right .4s;
    }

    @media screen and (max-width: 680px) {
        $line-dot-left: 2%;

        &::after {
            left: $line-dot-left;
        }

        &::before {
            left: calc(#{$line-dot-left} + 6px);
        }
    }

    @media screen and (min-width: $breakpoint-pad) {
        &_visible &Date {
            animation: timeline-show-left .4s;
        }
    }
}

@keyframes timeline-show-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes timeline-show-left {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}
