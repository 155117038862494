.Footer {
    display: flex;

    justify-content: space-between;

    padding: 16px;

    font-size: .9em;

    background: #202020;
    color: #9F9F9F;

    &-Language {
        cursor: pointer;
        color: inherit;
    }
}
