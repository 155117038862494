@import '../../common.scss';

.Timeline {
    width: auto;

    &-Inner {
        display: flex;
        flex-flow: column nowrap;
    }
}
