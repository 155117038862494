@import '../../common.scss';

.Header,
.Header-Content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $breakpoint-mobile) {
        flex-direction: row;
    }
}

.Header {
    height: 80vh;
    min-height: 420px;

    background: {
        image: url('../../images/main-bg.webp');
        repeat: no-repeat;
        position: center -10px;
        attachment: fixed;
        color: #0B0A04;
        size: cover;
    };

    color: #FFFFFF;

    &-Content {
        padding: 40px 20px;

        @media screen and (min-width: $breakpoint-mobile) {
            width: 80%;
            max-width: 900px;
            padding: unset;
        }
    }

    &-Photo {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;

        @media screen and (min-width: $breakpoint-mobile) and (max-width: #{$breakpoint-mobile - 1}) {
            @include size(170px);
        }

        img {
            max-width: 100%;
            border-radius: 50%;
        }
    }

    &-Aside {
        margin-top: 32px;
    }

    @media screen and (min-width: $breakpoint-mobile) {
        height: 70vh;
        min-height: 420px;

        &-Aside {
            margin-top: 0;
            margin-left: 32px;
        }
    }

    @media screen and (max-width: #{$breakpoint-mobile - 1}) {
        text-align: center;
    }

    h1,
    h2,
    h3 {
        font-weight: normal;
        line-height: 1.5;
        margin: 0;
    }

    h1 {
        @include font-size-with-media(2em, 2.1em, 3em);
    }

    h2 {
        @include font-size-with-media(1.4em, 1.5em, 1.7em);

        @media screen and (min-width: $breakpoint-mobile) {
            text-align: left;
        }
    }

    h3 {
        @include font-size-with-media(1.1em, 1.15em, 1.2em);
    }
}
